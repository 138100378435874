import React from "react";

const Dashboard = () => {
  return (
    <div>
      <p>Dashboard Work</p>
    </div>
  );
};

export default Dashboard;
